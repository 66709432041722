import React, { useState, useEffect, useRef } from 'react';
import { Mic, Video, Globe, Zap, Edit3, Languages } from 'lucide-react';

const LandingPage = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 510);
  const waitlistRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 510);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const response = await fetch(
        'https://script.google.com/macros/s/AKfycbynyh0BOR7jrMxabgcSCLkBmzxeGXi_qkSQvL5_Ts56r7RdSVYRiX0IwfLP6uXWfG9V/exec',
        {
          method: 'POST',
          body: new FormData(e.target),
        }
      );

      if (response.ok) {
        setSubmitMessage('Thank you for joining the waitlist!');
        setEmail('');
      } else {
        setSubmitMessage('An error occurred. Please try again.');
      }
    } catch (error) {
      setSubmitMessage('An error occurred. Please try again.');
    }

    setIsSubmitting(false);
  };

  const scrollToWaitlist = () => {
    waitlistRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const navItemStyle = {
    display: isMobile ? 'none' : 'inline',
    marginRight: '1rem',
    color: 'white',
    textDecoration: 'none',
  };

  const buttonStyle = {
    display: isMobile ? 'none' : 'inline',
    backgroundColor: '#ffcc00',
    color: '#4b0082',
    padding: '0.5rem 1rem',
    borderRadius: '9999px',
    transition: 'background-color 0.3s, color 0.3s',
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-400 via-indigo-500 to-purple-600 font-sans text-white">
      <div className="container mx-auto px-4 py-8">
        <header className="flex justify-between items-center mb-12">
          <div className="flex items-center">
            <Video className="w-10 h-10 text-yellow-300" />
            <span className="ml-2 text-2xl font-bold">VIDOREN</span>
          </div>
          <nav>
            <a href="#features" style={navItemStyle}>Features</a>
            <a href="#pricing" style={navItemStyle}>Pricing</a>
            <button
              onClick={scrollToWaitlist}
              style={buttonStyle}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = 'white';
                e.currentTarget.style.color = '#4b0082';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = '#ffcc00';
                e.currentTarget.style.color = '#4b0082';
              }}
            >
              Get Started
            </button>
          </nav>
        </header>

        <main className="text-center">
          <section className="mb-20">
            <h1 className="text-6xl font-bold mb-6 animate-pulse">Vidoren</h1>
            <p className="text-3xl mb-10">
              Because <span className="bg-yellow-300 text-indigo-700 px-2 py-1 rounded-full">captions</span> should be as creative as you are.
            </p>
            <button
              onClick={scrollToWaitlist}
              className="bg-white text-indigo-700 px-8 py-4 rounded-full text-xl font-semibold hover:bg-yellow-300 transition duration-300 transform hover:scale-105"
            >
              Unleash Your Creativity Now
            </button>
          </section>

          <section id="features" className="mb-20">
            <h2 className="text-4xl font-bold mb-12">Supercharge Your Content</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-12">
              {[
                { icon: Mic, title: "Easy Transcripts", desc: "Add captions to short-form videos effortlessly", color: "bg-green-400" },
                { icon: Zap, title: "Dynamic Effects", desc: "Apply stunning animation effects to your text", color: "bg-yellow-300" },
                { icon: Globe, title: "Long Video Subtitles", desc: "Caption documentaries and long-form content", color: "bg-blue-400" },
                { icon: Edit3, title: "Intuitive Editor", desc: "Edit captions with our user-friendly interface", color: "bg-pink-400" },
                { icon: Languages, title: "Multi-language", desc: "Transcribe in over 10 languages", color: "bg-purple-400" },
                { icon: Globe, title: "Content Localization", desc: "Reach global audiences with localized content", color: "bg-red-400" }
              ].map((feature, index) => (
                <div key={index} className="bg-white bg-opacity-10 p-6 rounded-lg backdrop-filter backdrop-blur-lg hover:bg-opacity-20 transition duration-300">
                  <feature.icon className={`w-12 h-12 ${feature.color} text-white p-2 rounded-full mb-4 mx-auto`} />
                  <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                  <p className="text-gray-100">{feature.desc}</p>
                </div>
              ))}
            </div>
          </section>

          <section id="pricing" className="mb-20" ref={waitlistRef}>
            <h2 className="text-4xl font-bold mb-12">Join the Creator Revolution</h2>
            <div className="bg-white text-indigo-700 p-8 rounded-lg inline-block max-w-md mx-auto transform hover:scale-105 transition duration-300">
              <h3 className="text-3xl font-semibold mb-4">Launch Offer</h3>
              <p className="text-5xl font-bold mb-6">15% OFF</p>
              <p className="mb-8 text-lg">Be among the first to elevate your content game!</p>
              <form onSubmit={handleSubmit} className="space-y-4">
                <input
                  name="Email"
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="w-full p-4 border border-indigo-300 rounded-full mb-4 text-center focus:outline-none focus:ring-2 focus:ring-indigo-600"
                />
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full bg-indigo-600 text-white py-4 rounded-full text-xl font-semibold hover:bg-indigo-700 transition duration-300 disabled:opacity-50"
                >
                  {isSubmitting ? 'Joining...' : 'Join Waitlist'}
                </button>
                {submitMessage && (
                  <p className="text-sm font-semibold mt-2">{submitMessage}</p>
                )}
                <p className="text-sm mt-2">
                  Join now and get <span className="font-bold text-yellow-500">15% discount</span> on launch!
                </p>
              </form>
            </div>
          </section>
        </main>

        <footer className="text-center text-white">
          <p>&copy; 2024 Vidoren. Empowering content creators worldwide.</p>
        </footer>
      </div>
    </div>
  );
};

export default LandingPage;
